import React from 'react'
import { styled } from '@mui/material/styles';
import Index from '../../../Index';
import PagesIndex from '../../../PagesIndex';
import './roleManagement.css';
import './roleManagement.responsive.css'

// for modal design 

const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.paper',
      boxShadow: 24,
};


// for table data


function createData(name, address, city, number, stutus, action) {
      return { name, address, city, number, stutus, action };
}


// for custom checkbox design


const BpIcon = styled('span')(({ theme }) => ({
      borderRadius: 3,
      border: "1px solid #114A65",
      width: 14,
      height: 14,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: "none",
      backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : 'transparent',
}));

const BpCheckedIcon = styled(BpIcon)({
      backgroundColor: 'transparent',
      '&:before': {
            display: 'block',
            width: 12,
            height: 12,
            backgroundImage:
                  "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                  " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                  "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23114A65'/%3E%3C/svg%3E\")",
            content: '""',
      },
      'input:hover ~ &': {
            backgroundColor: 'transparent',
      },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
      return (
            <Index.Checkbox
                  sx={{
                        '&:hover': { bgcolor: 'transparent' },
                  }}
                  disableRipple
                  color="default"
                  checkedIcon={<BpCheckedIcon />}
                  icon={<BpIcon />}
                  inputProps={{ 'aria-label': 'Checkbox demo' }}
                  {...props}
            />
      );
}

export default function RoleManagement() {



      const [age, setAge] = React.useState('');

      const handleChangedropdown = (event) => {
            setAge(event.target.value);
      };


      // add user modal
      const [open, setOpen] = React.useState(false);
      const handleOpen = () => setOpen(true);
      const handleClose = () => setOpen(false);




      return (
            <>
                  <Index.Box className="admin-dashboard-content admin-user-list-content">
                        <Index.Box className="admin-user-list-flex admin-page-title-main">
                              <Index.Typography className='admin-page-title admin-user-list-page-title' component='h2' variant='h2'>Role Management</Index.Typography>
                              <Index.Box className="admin-userlist-btn-flex">
                                    <Index.Box className="admin-search-main">
                                          <Index.Box className="admin-search-box">
                                                <Index.Box className="admin-form-group">
                                                      <Index.TextField
                                                            fullWidth
                                                            id="fullWidth"
                                                            className="admin-form-control"
                                                            placeholder="Search user"
                                                      />
                                                      <img
                                                            src={PagesIndex.Svg.search}
                                                            className="admin-search-grey-img admin-icon" alt='search'
                                                      ></img>
                                                </Index.Box>
                                          </Index.Box>
                                    </Index.Box>
                                    <Index.Box className="admin-userlist-inner-btn-flex">
                                          <Index.Box className="admin-adduser-btn-main btn-main-primary">
                                                <Index.Button className='admin-adduser-btn btn-primary' onClick={handleOpen}><img src={PagesIndex.Svg.plus} className="admin-plus-icon" alt='plus' />Add Role</Index.Button>
                                          </Index.Box>
                                    </Index.Box>
                              </Index.Box>
                        </Index.Box>

                        <Index.Box className="card-border common-card">
                              <Index.Box className="admin-userlist-table-main page-table-main">
                                    <Index.TableContainer component={Index.Paper} className='table-container'>
                                          <Index.Table aria-label="simple table" className='table'>
                                                <Index.TableHead className='table-head'>
                                                      <Index.TableRow className='table-row'>
                                                            <Index.TableCell component='th' variant='th' className='table-th' width="10%">NAME</Index.TableCell>
                                                            <Index.TableCell component='th' variant='th' className='table-th' width="10%">ADDRESS</Index.TableCell>
                                                            <Index.TableCell component='th' variant='th' className='table-th' width="10%">CITY</Index.TableCell>
                                                            <Index.TableCell component='th' variant='th' className='table-th' width="10%">NUMBER</Index.TableCell>
                                                            <Index.TableCell component='th' variant='th' className='table-th' width="10%">STATUS</Index.TableCell>
                                                            <Index.TableCell component='th' variant='th' align='right' className='table-th' width="10%">ACTION</Index.TableCell>
                                                      </Index.TableRow>
                                                </Index.TableHead>
                                                <Index.TableBody className='table-body'>
                                                      <Index.TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                      >
                                                            <Index.TableCell component='td' variant='td' scope="row" className='table-td'>
                                                                  <Index.Box className="admin-table-data-flex"><img src={PagesIndex.Png.userlist1} className="admin-table-data-img " alt='User'></img> <Index.Typography className='admin-table-data-text'>GASTON</Index.Typography></Index.Box>
                                                            </Index.TableCell>
                                                            <Index.TableCell component='td' variant='td' className='table-td'>
                                                                  <Index.Box className="admin-table-data-flex"><Index.Typography className='admin-table-data-text'>  12974 Keebler Prairie, South Brendon, Id, Cl</Index.Typography></Index.Box>
                                                            </Index.TableCell>
                                                            <Index.TableCell component='td' variant='td' className='table-td'> Id, Cl New Kaelachester</Index.TableCell>
                                                            <Index.TableCell component='td' variant='td' className='table-td'>623-880-0509 X6880</Index.TableCell>
                                                            <Index.TableCell component='td' variant='td' className='table-td'>Status</Index.TableCell>
                                                            <Index.TableCell component='td' variant='td' align='right' className='table-td'><Index.Box className="admin-table-data-btn-flex "><Index.Button className='admin-table-data-btn'><img src={PagesIndex.Svg.blueedit} className='admin-icon' alt='Edit'></img></Index.Button><Index.Button className='admin-table-data-btn'><img src={PagesIndex.Svg.trash} className='admin-icon' alt='Trash'></img></Index.Button><Index.Button className='admin-table-data-btn'><img src={PagesIndex.Svg.yelloweye} className='admin-icon' alt='View'></img></Index.Button></Index.Box></Index.TableCell>
                                                      </Index.TableRow>

                                                      <Index.TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                      >
                                                            <Index.TableCell component='td' variant='td' scope="row" className='table-td'>
                                                                  <Index.Box className="admin-table-data-flex"><img src={PagesIndex.Png.userlist1} className="admin-table-data-img " alt='User'></img> <Index.Typography className='admin-table-data-text'>GASTON</Index.Typography></Index.Box>
                                                            </Index.TableCell>
                                                            <Index.TableCell component='td' variant='td' className='table-td'>
                                                                  <Index.Box className="admin-table-data-flex"><Index.Typography className='admin-table-data-text'>  12974 Keebler Prairie, South Brendon, Id, Cl</Index.Typography></Index.Box>
                                                            </Index.TableCell>
                                                            <Index.TableCell component='td' variant='td' className='table-td'> Id, Cl New Kaelachester</Index.TableCell>
                                                            <Index.TableCell component='td' variant='td' className='table-td'>623-880-0509 X6880</Index.TableCell>
                                                            <Index.TableCell component='td' variant='td' className='table-td'>Status</Index.TableCell>
                                                            <Index.TableCell component='td' variant='td' align='right' className='table-td'><Index.Box className="admin-table-data-btn-flex "><Index.Button className='admin-table-data-btn'><img src={PagesIndex.Svg.blueedit} className='admin-icon' alt='Edit'></img></Index.Button><Index.Button className='admin-table-data-btn'><img src={PagesIndex.Svg.trash} className='admin-icon' alt='Trash'></img></Index.Button><Index.Button className='admin-table-data-btn'><img src={PagesIndex.Svg.yelloweye} className='admin-icon' alt='View'></img></Index.Button></Index.Box></Index.TableCell>
                                                      </Index.TableRow>

                                                      <Index.TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                      >
                                                            <Index.TableCell component='td' variant='td' scope="row" className='table-td'>
                                                                  <Index.Box className="admin-table-data-flex"><img src={PagesIndex.Png.userlist1} className="admin-table-data-img " alt='User'></img> <Index.Typography className='admin-table-data-text'>GASTON</Index.Typography></Index.Box>
                                                            </Index.TableCell>
                                                            <Index.TableCell component='td' variant='td' className='table-td'>
                                                                  <Index.Box className="admin-table-data-flex"><Index.Typography className='admin-table-data-text'>  12974 Keebler Prairie, South Brendon, Id, Cl</Index.Typography></Index.Box>
                                                            </Index.TableCell>
                                                            <Index.TableCell component='td' variant='td' className='table-td'> Id, Cl New Kaelachester</Index.TableCell>
                                                            <Index.TableCell component='td' variant='td' className='table-td'>623-880-0509 X6880</Index.TableCell>
                                                            <Index.TableCell component='td' variant='td' className='table-td'>Status</Index.TableCell>
                                                            <Index.TableCell component='td' variant='td' align='right' className='table-td'><Index.Box className="admin-table-data-btn-flex "><Index.Button className='admin-table-data-btn'><img src={PagesIndex.Svg.blueedit} className='admin-icon' alt='Edit'></img></Index.Button><Index.Button className='admin-table-data-btn'><img src={PagesIndex.Svg.trash} className='admin-icon' alt='Trash'></img></Index.Button><Index.Button className='admin-table-data-btn'><img src={PagesIndex.Svg.yelloweye} className='admin-icon' alt='View'></img></Index.Button></Index.Box></Index.TableCell>
                                                      </Index.TableRow>
                                                </Index.TableBody>
                                          </Index.Table>
                                    </Index.TableContainer>
                              </Index.Box>
                              <Index.Box className="admin-pagination-main">
                                    <Index.Pagination count={3} variant="outlined" shape="rounded" className='admin-pagination' />
                              </Index.Box>
                        </Index.Box>
                  </Index.Box>


                  <Index.Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        className='admin-modal'
                  >
                        <Index.Box sx={style} className="admin-add-user-modal-inner-main admin-modal-inner">
                              <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                                    <Index.Box className="admin-modal-header" >
                                          <Index.Typography id="modal-modal-title" className='admin-modal-title' variant="h6" component="h2">
                                                Add Role
                                          </Index.Typography>
                                          <img src={PagesIndex.Svg.closeblack} className="admin-modal-close-icon" onClick={handleClose} alt='Close' />
                                    </Index.Box>
                                    <Index.Box className="add-role-main">
                                          <Index.Box className="add-role-content-main">
                                                <Index.Box className="admin-input-box admin-add-user-input">
                                                      <Index.FormHelperText className='admin-form-lable'>Role</Index.FormHelperText>
                                                      <Index.Box className="admin-form-group">
                                                            <Index.TextField
                                                                  fullWidth
                                                                  id="fullWidth"
                                                                  className="admin-form-control"
                                                                  placeholder=""
                                                            />
                                                      </Index.Box>
                                                </Index.Box>
                                          </Index.Box>
                                          <Index.Box className="add-permission-section">
                                                <Index.Typography className='add-permission-title'>Permissions</Index.Typography>
                                                <Index.Box className="role-permission-main">
                                                      <Index.Box className="role-permission-flex">
                                                            <Index.Box className="role-permission-left-main">
                                                                  <Index.Typography className="role-permission-name">User</Index.Typography>
                                                            </Index.Box>
                                                            <Index.Box className="role-permission-right-main">
                                                                  <Index.Box className="role-permission-check-flex">
                                                                        <Index.Box className="role-permission-check-main">
                                                                              <Index.Box className="admin-checkbox-main">
                                                                                    <BpCheckbox />
                                                                                    <Index.Typography className="admin-checkbox-lable">View</Index.Typography>
                                                                              </Index.Box>
                                                                        </Index.Box>
                                                                        <Index.Box className="role-permission-check-main">
                                                                              <Index.Box className="admin-checkbox-main">
                                                                                    <BpCheckbox />
                                                                                    <Index.Typography className="admin-checkbox-lable">Add</Index.Typography>
                                                                              </Index.Box>
                                                                        </Index.Box>
                                                                        <Index.Box className="role-permission-check-main">
                                                                              <Index.Box className="admin-checkbox-main">
                                                                                    <BpCheckbox />
                                                                                    <Index.Typography className="admin-checkbox-lable">Edit</Index.Typography>
                                                                              </Index.Box>
                                                                        </Index.Box>
                                                                        <Index.Box className="role-permission-check-main">
                                                                              <Index.Box className="admin-checkbox-main">
                                                                                    <BpCheckbox />
                                                                                    <Index.Typography className="admin-checkbox-lable">Delete</Index.Typography>
                                                                              </Index.Box>
                                                                        </Index.Box>
                                                                  </Index.Box>
                                                            </Index.Box>
                                                      </Index.Box>
                                                      <Index.Box className="role-permission-flex">
                                                            <Index.Box className="role-permission-left-main">
                                                                  <Index.Typography className="role-permission-name">Dashboard</Index.Typography>
                                                            </Index.Box>
                                                            <Index.Box className="role-permission-right-main">
                                                                  <Index.Box className="role-permission-check-flex">
                                                                        <Index.Box className="role-permission-check-main">
                                                                              <Index.Box className="admin-checkbox-main">
                                                                                    <BpCheckbox />
                                                                                    <Index.Typography className="admin-checkbox-lable">View</Index.Typography>
                                                                              </Index.Box>
                                                                        </Index.Box>
                                                                        <Index.Box className="role-permission-check-main">
                                                                              <Index.Box className="admin-checkbox-main">
                                                                                    <BpCheckbox />
                                                                                    <Index.Typography className="admin-checkbox-lable">Add</Index.Typography>
                                                                              </Index.Box>
                                                                        </Index.Box>
                                                                        <Index.Box className="role-permission-check-main">
                                                                              <Index.Box className="admin-checkbox-main">
                                                                                    <BpCheckbox />
                                                                                    <Index.Typography className="admin-checkbox-lable">Edit</Index.Typography>
                                                                              </Index.Box>
                                                                        </Index.Box>
                                                                        <Index.Box className="role-permission-check-main">
                                                                              <Index.Box className="admin-checkbox-main">
                                                                                    <BpCheckbox />
                                                                                    <Index.Typography className="admin-checkbox-lable">Delete</Index.Typography>
                                                                              </Index.Box>
                                                                        </Index.Box>
                                                                  </Index.Box>
                                                            </Index.Box>
                                                      </Index.Box>
                                                      <Index.Box className="role-permission-flex">
                                                            <Index.Box className="role-permission-left-main">
                                                                  <Index.Typography className="role-permission-name">User</Index.Typography>
                                                            </Index.Box>
                                                            <Index.Box className="role-permission-right-main">
                                                                  <Index.Box className="role-permission-check-flex">
                                                                        <Index.Box className="role-permission-check-main">
                                                                              <Index.Box className="admin-checkbox-main">
                                                                                    <BpCheckbox />
                                                                                    <Index.Typography className="admin-checkbox-lable">View</Index.Typography>
                                                                              </Index.Box>
                                                                        </Index.Box>
                                                                        <Index.Box className="role-permission-check-main">
                                                                              <Index.Box className="admin-checkbox-main">
                                                                                    <BpCheckbox />
                                                                                    <Index.Typography className="admin-checkbox-lable">Add</Index.Typography>
                                                                              </Index.Box>
                                                                        </Index.Box>
                                                                        <Index.Box className="role-permission-check-main">
                                                                              <Index.Box className="admin-checkbox-main">
                                                                                    <BpCheckbox />
                                                                                    <Index.Typography className="admin-checkbox-lable">Edit</Index.Typography>
                                                                              </Index.Box>
                                                                        </Index.Box>
                                                                        <Index.Box className="role-permission-check-main">
                                                                              <Index.Box className="admin-checkbox-main">
                                                                                    <BpCheckbox />
                                                                                    <Index.Typography className="admin-checkbox-lable">Delete</Index.Typography>
                                                                              </Index.Box>
                                                                        </Index.Box>
                                                                  </Index.Box>
                                                            </Index.Box>
                                                      </Index.Box>
                                                </Index.Box>
                                          </Index.Box>
                                    </Index.Box>
                                    <Index.Box className="admin-modal-user-btn-flex">
                                          <Index.Box className="admin-discard-btn-main border-btn-main">
                                                <Index.Button className='admin-discard-user-btn border-btn'>Discard</Index.Button>
                                          </Index.Box>
                                          <Index.Box className="admin-save-btn-main btn-main-primary">
                                                <Index.Button className='admin-save-user-btn btn-primary'><img src={PagesIndex.Svg.save} className="admin-user-save-icon" alt='Save'></img>Save</Index.Button>
                                          </Index.Box>
                                    </Index.Box>
                              </Index.Box>
                        </Index.Box>
                  </Index.Modal>




            </>
      )
}
