
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Index from '../../../Index';
import PagesIndex from '../../../PagesIndex';
import './dashBoard.css';
import './dashBoard.responsive.css';



// for custom progressbar design

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));


// for tabal data

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('#95954', <PagesIndex.PaidLable />, 'OCTOBER 25, 2022', 'JEFF BREITENBERG', '4953'),
  createData('#95954', <PagesIndex.FailedLable />, 'OCTOBER 25, 2022', 'JEFF BREITENBERG', '4953'),
  createData('#95954', <PagesIndex.PendingLable />, 'OCTOBER 25, 2022', 'JEFF BREITENBERG', '4953'),
  createData('#95954', <PagesIndex.PaidLable />, 'OCTOBER 25, 2022', 'JEFF BREITENBERG', '4953'),
  createData('#95954', <PagesIndex.PaidLable />, 'OCTOBER 25, 2022', 'JEFF BREITENBERG', '4953'),
];


export default function Dashboard() {
  return (
    <>

      <Index.Box className="admin-dashboard-content">
        <Index.Box className="admin-user-list-flex admin-page-title-main">
          <Index.Typography className='admin-page-title' component='h2' variant='h2'>Dashboard</Index.Typography>
        </Index.Box>
        <Index.Box className="admin-dashboad-row">
          <Index.Box className="admin-dash-card-row">
            <Index.Box className="grid-column">
              <Index.Box className="admin-dashboard-box common-card">
                <Index.Box className="admin-dashboard-inner-box">
                  <Index.Box className="admin-dash-left">
                    <Index.Typography className='admin-dash-text' variant='p' component='p'>Dashboard</Index.Typography>
                    <Index.Typography className='admin-dash-price' variant='h1' component='h1'>1325M</Index.Typography>
                  </Index.Box>
                  <Index.Box className="admin-dash-right">
                    <Index.Box className="admin-dash-icon-box">
                      <img src={PagesIndex.Svg.dashicon1} className="admin-dash-icons" alt='dashboard icon' />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="grid-column">
              <Index.Box className="admin-dashboard-box common-card">
                <Index.Box className="admin-dashboard-inner-box">
                  <Index.Box className="admin-dash-left">
                    <Index.Typography className='admin-dash-text' variant='p' component='p'>Total Companies</Index.Typography>
                    <Index.Typography className='admin-dash-price' variant='h1' component='h1'>547K</Index.Typography>
                  </Index.Box>
                  <Index.Box className="admin-dash-right">
                    <Index.Box className="admin-dash-icon-box">
                      <img src={PagesIndex.Svg.dashicon2} className="admin-dash-icons" alt='dashboard icon' />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="grid-column">
              <Index.Box className="admin-dashboard-box common-card">
                <Index.Box className="admin-dashboard-inner-box">
                  <Index.Box className="admin-dash-left">
                    <Index.Typography className='admin-dash-text' variant='p' component='p'>Total Subscribed Users</Index.Typography>
                    <Index.Typography className='admin-dash-price' variant='h1' component='h1'>12.3K</Index.Typography>
                  </Index.Box>
                  <Index.Box className="admin-dash-right">
                    <Index.Box className="admin-dash-icon-box">
                      <img src={PagesIndex.Svg.dashicon3} className="admin-dash-icons" alt='dashboard icon' />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="grid-column">
              <Index.Box className="admin-dashboard-box common-card">
                <Index.Box className="admin-dashboard-inner-box">
                  <Index.Box className="admin-dash-left">
                    <Index.Typography className='admin-dash-text' variant='p' component='p'>Total Income</Index.Typography>
                    <Index.Typography className='admin-dash-price' variant='h1' component='h1'>985K</Index.Typography>
                  </Index.Box>
                  <Index.Box className="admin-dash-right">
                    <Index.Box className="admin-dash-icon-box">
                      <img src={PagesIndex.Svg.dashicon4} className="admin-dash-icons" alt='dashboard icon' />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="grid-column">
              <Index.Box className="admin-dashboard-box common-card">
                <Index.Box className="admin-dashboard-inner-box">
                  <Index.Box className="admin-dash-left">
                    <Index.Typography className='admin-dash-text' variant='p' component='p'>Total Income</Index.Typography>
                    <Index.Typography className='admin-dash-price' variant='h1' component='h1'>985K</Index.Typography>
                  </Index.Box>
                  <Index.Box className="admin-dash-right">
                    <Index.Box className="admin-dash-icon-box">
                      <img src={PagesIndex.Svg.dashicon4} className="admin-dash-icons" alt='dashboard icon' />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="grid-column">
              <Index.Box className="admin-dashboard-box common-card">
                <Index.Box className="admin-dashboard-inner-box">
                  <Index.Box className="admin-dash-left">
                    <Index.Typography className='admin-dash-text' variant='p' component='p'>Total Income</Index.Typography>
                    <Index.Typography className='admin-dash-price' variant='h1' component='h1'>985K</Index.Typography>
                  </Index.Box>
                  <Index.Box className="admin-dash-right">
                    <Index.Box className="admin-dash-icon-box">
                      <img src={PagesIndex.Svg.dashicon4} className="admin-dash-icons" alt='dashboard icon' />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="grid-column">
              <Index.Box className="admin-dashboard-box common-card">
                <Index.Box className="admin-dashboard-inner-box">
                  <Index.Box className="admin-dash-left">
                    <Index.Typography className='admin-dash-text' variant='p' component='p'>Total Income</Index.Typography>
                    <Index.Typography className='admin-dash-price' variant='h1' component='h1'>985K</Index.Typography>
                  </Index.Box>
                  <Index.Box className="admin-dash-right">
                    <Index.Box className="admin-dash-icon-box">
                      <img src={PagesIndex.Svg.dashicon4} className="admin-dash-icons" alt='dashboard icon' />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

    </>)
}