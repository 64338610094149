import React from 'react'
import Index from '../../Index'
import PagesIndex from '../../PagesIndex'


export default function AuthBackground() {
      return (
            <>
                  <Index.Box className="admin-auth-left-bg-main">
                        <Index.Box className="admin-auth-left-bg">
                              {/* <Index.Link className="admin-auth-logo-main">
                                    <img src={PagesIndex.Gfi.logogfi} className="admin-auth-logo" alt='logo' />
                              </Index.Link> */}
                        </Index.Box>
                  </Index.Box>
            </>
      )
}
