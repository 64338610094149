
import Svg from '../assets/Svg'
import Png from "../assets/Png";
import Gfi from "../assets/Gif";
import Sidebar from "./admin/defaulLayout/Sidebar";
import Header from "./admin/defaulLayout/Header";
import PaidLable from "./common/lables/PaidLable";
import FailedLable from "./common/lables/FailedLable";
import PendingLable from "./common/lables/PendingLable";
import PrimaryButton from './common/Button/PrimaryButton';
import BorderButton from './common/Button/BorderButton';
import AuthFooter from "./admin/defaulLayout/AuthFooter";
import AuthBackground from "./admin/defaulLayout/AuthBackground";
import { useLocation, useNavigate } from "react-router-dom";
import EditProfile from './admin/pages/account/editProfile/EditProfile';
import ChangePassword from './admin/pages/account/changePassword/ChangePassword';

export default {
      Svg,
      Png,
      Sidebar,
      Header,
      PaidLable,
      FailedLable,
      PendingLable,
      PrimaryButton,
      AuthFooter,
      AuthBackground,
      useLocation,
      useNavigate,
      BorderButton,
      EditProfile,
      ChangePassword,
      Gfi,
};
