import React, { useEffect, useState } from 'react'
import PagesIndex from '../../PagesIndex';
import Index from '../../Index';
import './defaultLayout.css'
import './defaultLayout.responsive.css'



export default function Sidebar(props) {

      // for page redirect
      const location = PagesIndex.useLocation();


      // for submenu dropdown
      const [open, setOpen] = React.useState(true);
      const [screen, setScreen] = useState('');
      const handleClickAccount = () => {
            setOpen(!open);
      };


      // for mobile menu sidebar 
      useEffect(() => {
            if (window.screen.width < 767) {
                  setScreen('Mobile')
                  props.setOpen(false)
            } else {
                  setScreen('Desktop')
            }
      }, [window.screen.availHeight])

      return (
            <>

                  <Index.Box className={`admin-sidebar-main`}>
                        <Index.Box className="admin-sidebar-inner-main">
                              <Index.Link className="admin-sidebar-logo-main">
                                    <img src={PagesIndex.Gfi.logogfi} className="admin-sidebar-logo" alt='logo' />
                                    <Index.Button onClick={() => {
                                          props.setOpen(!props.open)
                                          document.body.classList[props.open ? "remove" : "add"](
                                                "admin-body-overflow"
                                          );
                                    }} className="admin-sidebar-close-btn">
                                          <img src={PagesIndex.Svg.close} className="admin-close-icon" alt='logo' />
                                    </Index.Button>
                              </Index.Link>

                              <Index.Box className="admin-sidebar-list-main scrollbar">
                                    <Index.List className="admin-sidebar-list">
                                          <Index.ListItem className="admin-sidebar-listitem">
                                                <Index.Tooltip title="Dashboard" arrow placement="right" className='admin-tooltip'>
                                                      <Index.Link to="/admin/dashboard"
                                                            onClick={() => {
                                                                  props.setOpen(!props.open)
                                                                  document.body.classList[props.open ? "add" : "remove"](
                                                                        "admin-body-overflow"
                                                                  );
                                                            }}
                                                            className={
                                                                  location?.pathname.includes("/admin/dashboard")
                                                                        ? "admin-sidebar-link active"
                                                                        : "admin-sidebar-link"
                                                            }>
                                                            <img src={PagesIndex.Svg.dashboard} alt='Dashboard' className='admin-sidebar-icons' /><span className='admin-sidebar-link-text'>Dashboard</span>
                                                      </Index.Link>
                                                </Index.Tooltip>
                                          </Index.ListItem>

                                          <Index.ListItem className="admin-sidebar-listitem">
                                                <Index.Tooltip title="User List" arrow placement="right" className='admin-tooltip'>
                                                      <Index.Link to="/admin/user-list"
                                                            onClick={() => {
                                                                  props.setOpen(!props.open)
                                                                  document.body.classList[props.open ? "add" : "remove"](
                                                                        "admin-body-overflow"
                                                                  );
                                                            }}
                                                            className={
                                                                  location?.pathname.includes("/admin/user-list")
                                                                        ? "admin-sidebar-link active"
                                                                        : "admin-sidebar-link"
                                                            }>
                                                            <img src={PagesIndex.Svg.userlist} alt='User List' className='admin-sidebar-icons' /><span className='admin-sidebar-link-text'>User List</span>
                                                      </Index.Link>
                                                </Index.Tooltip>
                                          </Index.ListItem>

                                          <Index.ListItem className="admin-sidebar-listitem">
                                                <Index.Tooltip title="User Card" arrow placement="right" className='admin-tooltip'>
                                                      <Index.Link to="/admin/user-card" onClick={() => {
                                                            props.setOpen(!props.open)
                                                            document.body.classList[props.open ? "add" : "remove"](
                                                                  "admin-body-overflow"
                                                            );
                                                      }}
                                                            className={
                                                                  location?.pathname.includes("/admin/user-card")
                                                                        ? "admin-sidebar-link active"
                                                                        : "admin-sidebar-link"
                                                            }>
                                                            <img src={PagesIndex.Svg.usercard} alt='User Card' className='admin-sidebar-icons' /><span className='admin-sidebar-link-text'>User Card</span>
                                                      </Index.Link>
                                                </Index.Tooltip>
                                          </Index.ListItem>

                                          <Index.ListItem className="admin-sidebar-listitem">
                                                <Index.Tooltip title="Role Management" arrow placement="right" className='admin-tooltip'>
                                                      <Index.Link to="/admin/role-management" onClick={() => {
                                                            props.setOpen(!props.open)
                                                            document.body.classList[props.open ? "add" : "remove"](
                                                                  "admin-body-overflow"
                                                            );
                                                      }}
                                                            className={
                                                                  location?.pathname.includes("/admin/role-management")
                                                                        ? "admin-sidebar-link active"
                                                                        : "admin-sidebar-link"
                                                            }>
                                                            <img src={PagesIndex.Svg.rolemanagment} alt='User Card' className='admin-sidebar-icons' /><span className='admin-sidebar-link-text'>Role Management</span>
                                                      </Index.Link>
                                                </Index.Tooltip>
                                          </Index.ListItem>

                                          <Index.ListItem className="admin-sidebar-listitem">
                                                <Index.Tooltip title="Edit Profile" arrow placement="right" className='admin-tooltip'>
                                                      <Index.Link to="/admin/account" onClick={() => {
                                                            props.setOpen(!props.open)
                                                            document.body.classList[props.open ? "add" : "remove"](
                                                                  "admin-body-overflow"
                                                            );
                                                      }}
                                                            className={
                                                                  location?.pathname.includes("/admin/account")
                                                                        ? "admin-sidebar-link active"
                                                                        : "admin-sidebar-link"
                                                            }>
                                                            <img src={PagesIndex.Svg.profileediticon} alt='Edit Profile' className='admin-sidebar-icons' /><span className='admin-sidebar-link-text'>Edit Profile</span>
                                                      </Index.Link>
                                                </Index.Tooltip>
                                          </Index.ListItem>

                                          <Index.ListItem className='admin-sidebar-listitem admin-submenu-listitem-main' onClick={handleClickAccount}>
                                                <Index.Box className='admin-submenu-link-box'>
                                                      <Index.Tooltip title="CMS" arrow placement="right" className='admin-tooltip'>
                                                            <Index.Link className="admin-sidebar-link">
                                                                  <img src={PagesIndex.Svg.changepassword} alt='CMS' className='admin-sidebar-icons' /><span className='admin-sidebar-link-text'>CMS</span>
                                                            </Index.Link>
                                                      </Index.Tooltip>
                                                      {open ? <Index.ExpandMore className='expandmore-icon' /> : <Index.ExpandLess className='expandless-icon' />}
                                                      <Index.Box className='admin-submenu-main'>
                                                            <Index.Collapse in={open} timeout="auto" unmountOnExit className='admin-submenu-collapse'>
                                                                  <Index.List component="div" disablePadding className='admin-sidebar-submenulist'>
                                                                        <Index.ListItem className='admin-sidebar-listitem'>
                                                                              <Index.Tooltip title="About" arrow placement="right" className='admin-tooltip'>
                                                                                    <Index.Link className='admin-sidebar-link' onClick={() => {
                                                                                          props.setOpen(!props.open)
                                                                                          document.body.classList[props.open ? "add" : "remove"](
                                                                                                "admin-body-overflow"
                                                                                          );
                                                                                    }}>
                                                                                          <img src={PagesIndex.Svg.abouticon} alt='About' className='admin-sidebar-icons' /><span className='admin-sidebar-link-text'>About</span>
                                                                                    </Index.Link>
                                                                              </Index.Tooltip>
                                                                        </Index.ListItem>
                                                                        <Index.ListItem className='admin-sidebar-listitem'>
                                                                              <Index.Tooltip title="Terms & Conditions" arrow placement="right" className='admin-tooltip'>
                                                                                    <Index.Link to="/admin/terms-and-condition" onClick={() => {
                                                                                          props.setOpen(!props.open)
                                                                                          document.body.classList[props.open ? "add" : "remove"](
                                                                                                "admin-body-overflow"
                                                                                          );
                                                                                    }} className={
                                                                                          location?.pathname.includes("/admin/terms-and-condition")
                                                                                                ? "admin-sidebar-link active"
                                                                                                : "admin-sidebar-link"}>
                                                                                          <img src={PagesIndex.Svg.termsandconditionicon} alt='Terms & Conditions' className='admin-sidebar-icons' /><span className='admin-sidebar-link-text'>Terms & Conditions</span>
                                                                                    </Index.Link>
                                                                              </Index.Tooltip>
                                                                        </Index.ListItem>
                                                                        <Index.ListItem className='admin-sidebar-listitem'>
                                                                              <Index.Tooltip title="Privacy Policy" arrow placement="right" className='admin-tooltip'>
                                                                                    <Index.Link to="/admin/privacy-policy" onClick={() => {
                                                                                          props.setOpen(!props.open)
                                                                                          document.body.classList[props.open ? "add" : "remove"](
                                                                                                "admin-body-overflow"
                                                                                          );
                                                                                    }} className={
                                                                                          location?.pathname.includes("/admin/privacy-policy")
                                                                                                ? "admin-sidebar-link active"
                                                                                                : "admin-sidebar-link"}>
                                                                                          <img src={PagesIndex.Svg.policyicon} alt='Privacy Policy' className='admin-sidebar-icons' /><span className='admin-sidebar-link-text'>Privacy Policy</span>
                                                                                    </Index.Link>
                                                                              </Index.Tooltip>
                                                                        </Index.ListItem>
                                                                  </Index.List>
                                                            </Index.Collapse>
                                                      </Index.Box>
                                                </Index.Box>
                                          </Index.ListItem>
                                    </Index.List>
                              </Index.Box>
                        </Index.Box>
                  </Index.Box >
            </>
      )
}
