
import Svg from '../assets/Svg'
import Png from "../assets/Png";
import Sidebar from '../component/admin/defaulLayout/Sidebar';
import Header from '../component/admin/defaulLayout/Header';
import PaidLable from '../component/common/lables/PaidLable';
import FailedLable from '../component/common/lables/FailedLable';
import PendingLable from '../component/common/lables/PendingLable';
import PrimaryButton from '../component/common/Button/PrimaryButton';
import BorderButton from '../component/common/Button/BorderButton';
import AuthBackground from '../component/admin/defaulLayout/AuthBackground';
import AuthFooter from '../component/admin/defaulLayout/AuthFooter';
import EditProfile from '../component/admin/pages/account/editProfile/EditProfile';
import ChangePassword from '../component/admin/pages/account/changePassword/ChangePassword';



export default {
      Svg,
      Png,
      Sidebar,
      Header,
      PaidLable,
      FailedLable,
      PendingLable,
      PrimaryButton,
      AuthFooter,
      AuthBackground,
      BorderButton,
      EditProfile,
      ChangePassword,
};
