import React from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes as Routess,
} from "react-router-dom";
import { createBrowserHistory } from 'history'
import { useSelector } from "react-redux";


//#start region Admin
import Login from '../component/admin/auth/login/Login'
import ForgotPassword from '../component/admin/auth/forgotPassword/ForgotPassword'
import Otp from '../component/admin/auth/otp/Otp'
import SignIn from '../component/admin/auth/signIn/SignIn'
import AdminLayOut from "../container/admin/pages/adminLayout/AdminLayOut";
import UserList from "../container/admin/pages/userList/UserList";
import DashBoard from '../container/admin/pages/dashBoard/DashBoard';
import AddUser from "../container/admin/pages/addUser/AddUser";
import UserCard from "../container/admin/pages/userCard/UserCard";
import AccountLayout from "../container/admin/pages/accountLayout/AccountLayout";
import PrivacyPolicy from "../container/admin/pages/privacyPolicy/PrivacyPolicy";
import TermsAndCondition from "../container/admin/pages/termsAndCondition/TermsAndCondition";
import RoleManagement from "../container/admin/pages/roleManagement/RoleManagement";
//#endregion



const history = createBrowserHistory();
export default function Routes() {

  const login = useSelector((state) => {
    return state.AdminReducer.isAdminLogin
  });
  // const login = true;

  return (
    <BrowserRouter history={history}>
      <Routess>
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="otp-verify" element={<Otp />} />
        <Route path="sign-in" element={<SignIn />} />
        <Route path="/admin" element={<AdminLayOut />}>
          <Route path="dashboard" element={<DashBoard />} />
          <Route path="account" element={<AccountLayout />} />
          <Route path="user-list" element={<UserList />} />
          <Route path="user-card" element={<UserCard />} />
          <Route path="add-user" element={<AddUser />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-and-condition" element={<TermsAndCondition />} />
          <Route path="role-management" element={<RoleManagement />} />
        </Route>
      </Routess>
    </BrowserRouter>
  );
}


