import logogfi from './images/gif/logo.gif';








const Gif = {
      logogfi
}

export default Gif;